body::-webkit-scrollbar {
  width: 0.7rem;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgb(190, 190, 190);
  outline: 1px solid rgb(172, 173, 173);
}

.sub-menu-group-icon {
  width: 30px;
  height: 30px;
}

.main-menu-nav-link {
  color: black;
}

.main-menu-nav-link .active {
  color: #54d1c4;
}

.main-menu-nav-link:hover {
  color: #54d1c4;
}

.react-select-container-currency {
  caret-color: transparent;
}
